import { configureStore } from '@reduxjs/toolkit'
import themeReducer from 'redux/features/theme'
import authReducer from 'redux/features/auth'
import suratReducer from 'redux/features/surat'
import penggunaReducer from 'redux/features/pengguna'

const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    surat:suratReducer,
    pengguna:penggunaReducer
  }
});

export default store;

