import { Col, Row } from 'antd';
import React from "react";
import { Card, Form, Input } from 'antd';
import { Select, DatePicker, Modal } from 'antd';
import { Image, Video, Transformation } from 'cloudinary-react';
import { Spin } from 'antd';
import { Button, message, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment';
import { addSurat, deleteSurat, fetchOneSurat, updateSurat } from 'redux/features/surat';
import { useState } from 'react';
import { Spinner } from 'reactstrap';


const { TextArea } = Input;

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

const rules = [
    {
        required: true,
        message: 'Wajib Diisi!',
    }
]

const options = [
    {
        value: 'BELUM',
        label: 'BELUM',
    },
    {
        value: 'SUDAH',
        label: 'SUDAH',
    }
]

export const DETAIL_SURAT_KELUAR = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState(null);
    const location = useLocation()
    const [surat, setSurat] = useState({
        _id: "",
        externalFile: "",
        jenisSurat: "KELUAR",
        judulSurat: "",
        keterangan: "",
        linkFile: "",
        nomorSurat: "",
        statusMaterai: "",
        statusSurat: "",
        tanggalSurat: ""
    })

    const [isLoading,setLoading] = useState(false)
    const [visible, setVisible] = useState(false);
    const [statusSuratState, setStatusSurat] = useState("");
    const [statusMateraiState, setStatusMaterai] = useState("");

    const handleOk = async () => {
        try {
            setVisible(false);
            await dispatch(deleteSurat(surat._id)).unwrap()
            history.push({
                pathname: '/app/surat-keluar'
            })
        } catch (err) {
            console.log(err)
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const createSuratMethod = async (values) => {
        try {
            await dispatch(addSurat({
                ...values,
                externalFile: imageUrl !== '' ? imageUrl : values.externalFile,
                statusSurat: statusSuratState,
                statusMaterai: statusMateraiState,
                jenisSurat: "KELUAR",
                tanggalSurat: moment(values.tanggalSurat).format("YYYY-MM-DD")
            })).unwrap()
            message.success("Surat Berhasil Dibuat! Kembali Ke Halaman Depan...")
            setTimeout(() => {
                history.push({
                    pathname: '/app/surat-keluar'
                })
            }, 1000)
        } catch (err) {
            console.log(err)
        }
    }

    const updateSuratMethod = async (values) => {
        try {
            await dispatch(updateSurat({
                id: surat._id,
                ...values,
                externalFile: imageUrl !== '' ? imageUrl : values.externalFile,
                statusSurat: statusSuratState,
                statusMaterai: statusMateraiState,
                tanggalSurat: moment(values.tanggalSurat).format("YYYY-MM-DD")
            })).unwrap()
            message.success("Surat Berhasil Diupdate! Kembali Ke Halaman Depan...")
            setTimeout(() => {
                history.push({
                    pathname: '/app/surat-keluar'
                })
            }, 1000)
        } catch (err) {
            console.log(err)
        }
    }

    const onFinish = async (values) => {
        try {
            if (location.id) {
                updateSuratMethod(values)
            } else {
                createSuratMethod(values)
            }
        }
        catch (err) {

        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChangeStatusSurat = async (value) => {
        console.log(`selected ${value}`);
        setStatusSurat(value)
    };

    const handleChangeMateraiSurat = async (value) => {
        console.log(`selected ${value}`);
        setStatusMaterai(value)
    };

    const getDataById = async (id) => {
        try {
            const dataSurat = await dispatch(fetchOneSurat(id)).unwrap()
            setStatusSurat(dataSurat.statusSurat)
            setStatusMaterai(dataSurat.statusMaterai)
            setImageUrl(dataSurat.externalFile)
            form.setFieldsValue({
                ...dataSurat,
                tanggalSurat: moment(dataSurat.tanggalSurat)
            });
            setSurat(dataSurat)
        } catch (err) {
            console.log(err)
        }
    }

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'surabah');
        formData.append('api_key', '997139754242815');
        formData.append("cloud_name","sangsakawira")
        setLoading(true)
        try {
            const response = await fetch(
                'https://api.cloudinary.com/v1_1/sangsakawira/image/upload',
                {
                    method: 'POST',
                    body: formData,
                }
            );
            const data = await response.json();
            setImageUrl(data.secure_url);
            setLoading(false)
        } catch (err) {
            console.log(err)
            console.error('Error uploading image:', err);
        }
    };

    const uploading = () =>{
        if(imageUrl && !isLoading){
            return (<b style={{color:"green",marginTop:"5px"}}>File Uploaded!</b>)
        }else if(!imageUrl && !isLoading){
            return (<b style={{color:"red",marginTop:"5px"}}>File Not Uploaded!</b>)
        }else{
            return ( (<b style={{marginTop:"5px"}}><Spin />Uploading</b>))
        }
    }


    useEffect(() => {
        if (location.id) {
            getDataById(location.id)
        }
    }, [])

    return (
        <>
            <Modal
                title="Hapus Data"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>Anda yakin ingin melakukan aksi ini?</p>
            </Modal>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Surat KELUAR</h2>
                    <p>Summary analisa data aplikasi kali ini</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Form
                            form={form}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Perihal Surat"
                                name="perihal"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Tanggal Surat"
                                name="tanggalSurat"
                                rules={rules}
                            >
                                <DatePicker style={selectStyle} />
                            </Form.Item>

                            <Form.Item
                                label="Nomor Surat"
                                name="nomorSurat"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Penerima"
                                name="penerima"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Nama PIC"
                                name="pic"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Quantity Materai"
                                name="quantity"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Hard"
                                name="hard"
                                rules={rules}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Project"
                                name="project"
                                rules={rules}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                            
                            <Form.Item
                                label="Upload File (Jika File dibawah 10 mb)"
                                name="upload_file"
                            >

                                <Input type="file" onChange={handleImageUpload} />
                                {uploading()}
                            </Form.Item>

                            <Form.Item
                                label="Link External (Opsional Jika File diatas 20 mb)"
                                name="externalFile"
                            >
                                <Input defaultValue={imageUrl} />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isLoading}>
                                    {location.id ? "Update" : "Tambah"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>

                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="danger" style={{ border: "0px", color: "white" }} htmlType="submit" onClick={() => {
                        setVisible(true)
                    }} block>
                        Hapus Surat
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default DETAIL_SURAT_KELUAR