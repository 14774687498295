import { Col, Row, message } from 'antd';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import React, { useEffect, useState, useCallback } from "react";
import { fetchAllSurat, fetchAllByTanggalFilter, dashboard } from 'redux/features/surat';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { LAPORAN } from '../laporan';

export const AnnualStatisticData = [
  {
    title: 'Total Surat',
    value: 'Loading...',
  },
  {
    title: 'Surat Masuk',
    value: 'Loading...',
  },
  {
    title: 'Surat Keluar',
    value: 'Loading...',
  }
]

export const DefaultDashboard = () => {

  const dispatch = useDispatch()

  const {
    count,
    selectedRows,
    filter: { q: searchTerm },
    loading: {
        query: loadingQuery,
        mutation: loadingMutation
    }
} = useSelector(state => state.surat)

  const getData = useCallback(async () => {
    try {
      await dispatch(dashboard()).unwrap()
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>Jika menemukan bug ataupun error pada aplikasi ini bisa langsung <a target='_blank' href='https://wa.me/6285899731884?text=Hi min, ada error atau bug di aplikasi surat surabah'>klik sini </a></p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
        <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={1}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Surat Masuk`}
                  value={count.suratMasuk}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Surat Keluar`}
                  value={count.suratKeluar}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Total Surat`}
                  value={count.totalSurat}
                />
              </Col>
            </Row>
        </Col>
      </Row>
      <LAPORAN noTitle={true} ></LAPORAN>
    </>
  )
}


export default withRouter(DefaultDashboard);
