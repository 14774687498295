import { Col, Row } from 'antd';
import React from "react";
import { Button, Card, Form, Input } from 'antd';
import { Select } from 'antd';
import { useEffect } from 'react';


const selectStyle = {
  width: "100%",
  backgroundColor: "white"
};

const rules = [
  {
      required: true,
      message: 'Wajib Diisi!',
  }
]

const options = [
  {
      value: 'Admin',
      label: 'Admin',
  },
  {
      value: 'User',
      label: 'User',
  }
]

export const SETTINGS = () => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
};

  const onFinish = (values) => {
    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    console.log("Dashboard is running!")
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Atur Pengguna</h2>
          <p>Atur pengguna dan privilege pengguna</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Privilege"
                name="status_surat"
                rules={rules}
              >
                <Select
                  defaultValue="User"
                  style={selectStyle}
                  onChange={handleChange}
                  options={options}
                />
              </Form.Item>

              <Form.Item
                label="Set New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item

              >
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  Update Pengguna
                </Button>
                <br />
                <br />
                <Button type="danger" htmlType="submit" style={{ width: "100%", color: "white" }}>
                  Delete Pengguna
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default SETTINGS